import gql from 'graphql-tag'

export const CONTACT_FIELDS = gql`
	fragment ContactFields on Contact {
		index
		contact_type
		name
		address
		name_en
		address_en
		email
		phone
	}
`;

export const RECORD_CONTACT = gql`
	fragment RecordContact on RecordContact {
		index
		name
		address
		name_en
		address_en
		email
		phone
	}
`;