import gql from 'graphql-tag'

import { CONTACT_FIELDS } from './fragments'

// For displaying users list for admin
export const USERS_LIST = gql`
	query search_backuser (
		$search_query: String,
		$account_type: Int,
		$account_active: Boolean,
	) {
		search_backuser (
			search_query: $search_query,
			account_type: $account_type,
			account_active: $account_active,
		) {
			index
			account_type {
				name
				code
			}
			primary_contact {
				name
				contact_type
			}
		}
	}
`

export const SUBMITTERS_LIST = gql`
	query get_submitters {
		search_backuser (
			account_type: 201,
			search_query: null,
			account_active: true,
		) {
			index
			account_type {
				name
				code
			}
			primary_contact {
				name
			}
		}
	}
`

// Get user by ID
export const USER_DETAIL = gql`
	${CONTACT_FIELDS}
	query get_backuser ($index: Int) {
		get_backuser (index: $index) {
			index
			account_type {
				name
				code
			}
			account_active
			email
			primary_contact {
				...ContactFields
			}
			contacts {
				...ContactFields
			}
		}
	}
`

// Create user
export const CREATE_USER = gql`
	mutation create_backuser (
		$account_type: Int!,
		$contact_type: String!,
		$email: String!,
		$phone: String!,
		$name: String!,
		$address: String!,
		$name_en: String,
		$address_en: String,
	) {
		create_backuser (
			account_type: $account_type,
			contact_type: $contact_type,
			email: $email,
			name: $name,
			address: $address,
			name_en: $name_en,
			address_en: $address_en,
			phone: $phone,
		) {
			index
		}
	}
`

export const USER_CHANGE_PASSWORD = gql`
	mutation change_password (
		$old_pass: String!,
		$new_pass: String!
	) {
		change_password (
			old_pass: $old_pass,
			new_pass: $new_pass
		)
	}
`

export const USER_DEACTIVATE_ACCOUNT = gql`
	mutation deactivate_account (
		$user_index: Int,
		$account_active: Boolean
	) {
		deactivate_account (
			user_index: $user_index,
			account_active: $account_active
		)
	}
`

export const USER_NOTIFICATIONS = gql`
	query get_notifications {
		get_backuser {
			notifications {
				notification_type
				date
				target
				text
				seen
			}
		}
	}
`

export const SET_NOTIFICATIONS_SEEN = gql`
	mutation set_notifications_seen {
		set_notifications_seen
	} 
`

export const CLEAN_NOTIFICATIONS = gql`
	mutation clean_notifications {
		clean_notifications
	} 
`