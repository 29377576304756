import {
	SubmissionStatus,
	InvoiceStatus,
	ContactType,
	UserType,
	SubmissionType,
	DisinfectantTestType,
	GeneralTestDepartment,
} from '@/enum'

export const LISTS = {
	SUBMISSION_STATUSES: Object.values(SubmissionStatus),
	INVOICE_STATUSES: Object.values(InvoiceStatus),
	CONTACT_TYPES: Object.values(ContactType),
	USER_TYPES: Object.values(UserType),
	SUBMISSION_TYPES: Object.values(SubmissionType),
	DISINFECTANT_TEST_TYPES: Object.values(DisinfectantTestType),
}

export const COLORS = {
	SUBMISSION_TYPE: {
		[SubmissionType.GENERAL]: 'primary',
		[SubmissionType.DISINFECTANT]: 'blue',
	},
	
	SUBMISSION_STATUS: {
		[SubmissionStatus.AWAIT_SAMPLE]: 'grey',
		[SubmissionStatus.IN_PROGRESS]: 'yellow',
		[SubmissionStatus.COMPLETED]: 'green',
		[SubmissionStatus.CANCELLED]: 'red',
	},
	
	INVOICE_STATUS: {
		[InvoiceStatus.AWAIT_PAYMENT]: 'yellow',
		[InvoiceStatus.PAID]: 'green'
	},

	CONTACT_TYPE: {
		'แอดมิน': 'pink',
		[ContactType.ORG]: 'red',
		[ContactType.PERSON]: 'orange',
	},

	USER_TYPE: {
		[UserType.SUBMITTER]: 'purple',
		[UserType.ADMIN]: 'pink'
	},

	GENERAL_TEST_DEPARTMENT: {
		[GeneralTestDepartment.VIROLOGY]: 'yellow',
		[GeneralTestDepartment.SEROLOGY]: 'orange',
		[GeneralTestDepartment.MOLECULAR]: 'red',
		[GeneralTestDepartment.BACTERIOLOGY]: 'pink',
	},

	DISINFECTANT_TEST_TYPE: {
		[DisinfectantTestType.VIRUS]: 'yellow',
		[DisinfectantTestType.BACTERIA]: 'pink',
		[DisinfectantTestType.BACTERIA_CP]: 'red'
	}
}


export const LABELS = {
	USER_TYPE: {
		[UserType.SUBMITTER]: 'ผู้ส่งตัวอย่าง',
		[UserType.ADMIN]: 'แอดมิน'
	}
}

export const PROVINCES = [
	'กรุงเทพฯ',
	'กระบี่',
	'กาญจนบุรี',
	'กาฬสินธุ์',
	'กำแพงเพชร',
	'ขอนแก่น',
	'จันทบุรี',
	'ฉะเชิงเทรา',
	'ชลบุรี',
	'ชัยนาท',
	'ชัยภูมิ',
	'ชุมพร',
	'เชียงใหม่',
	'เชียงราย',
	'ตรัง',
	'ตราด',
	'ตาก',
	'นครนายก',
	'นครปฐม',
	'นครพนม',
	'นครราชสีมา',
	'นครศรีธรรมราช',
	'นครสวรรค์',
	'นนทบุรี',
	'นราธิวาส',
	'น่าน',
	'บึงกาฬ',
	'บุรีรัมย์',
	'ปทุมธานี',
	'ประจวบคีรีขันธ์',
	'ปราจีนบุรี',
	'ปัตตานี',
	'พระนครศรีอยุธยา',
	'พะเยา',
	'พังงา',
	'พัทลุง',
	'พิจิตร',
	'พิษณุโลก',
	'เพชรบุรี',
	'เพชรบูรณ์',
	'แพร่',
	'ภูเก็ต',
	'มหาสารคาม',
	'มุกดาหาร',
	'แม่ฮ่องสอน',
	'ยโสธร',
	'ยะลา',
	'ร้อยเอ็ด',
	'ระนอง',
	'ระยอง',
	'ราชบุรี',
	'ลพบุรี',
	'ลำปาง',
	'ลำพูน',
	'เลย',
	'ศรีสะเกษ',
	'สกลนคร',
	'สงขลา',
	'สตูล',
	'สมุทรปราการ',
	'สมุทรสงคราม',
	'สมุทรสาคร',
	'สระแก้ว',
	'สระบุรี',
	'สิงห์บุรี',
	'สุโขทัย',
	'สุพรรณบุรี',
	'สุราษฎร์ธานี',
	'สุรินทร์',
	'หนองคาย',
	'หนองบัวลำภู',
	'อ่างทอง',
	'อำนาจเจริญ',
	'อุดรธานี',
	'อุตรดิตถ์',
	'อุทัยธานี',
	'อุบลราชธานี',
]

export const PROVINCES_ENG = [
	'Bangkok',
	'Krabi',
	'Kanchanaburi',
	'Kalasin',
	'Kamphaengphet',
	'Khonkaen',
	'Chanthaburi',
	'Chachoengsao',
	'Chonburi',
	'Chainat',
	'Chaiyaphum',
	'Chumphon',
	'Chiangmai',
	'Chiangrai',
	'Trang',
	'Trat',
	'Tak',
	'Nakhonnayok',
	'Nakhonpathom',
	'Nakhonphanom',
	'Nakhonratchasima',
	'Nakhonsithammarat',
	'Nakhonsawan',
	'Nonthaburi',
	'Narathiwat',
	'Nan',
	'Buengkan',
	'Buriram',
	'Pathumthani',
	'Prachuapkhirikhan',
	'Prachinburi',
	'Pattani',
	'Pyutthaya',
	'Phayao',
	'Phangnga',
	'Phatthalung',
	'Phichit',
	'Phitsanulok',
	'Phetchaburi',
	'Phetchabun',
	'Phrae',
	'Phuket',
	'Mahasarakham',
	'Mukdahan',
	'Maehongson',
	'Yasothon',
	'Yala',
	'Roi-et',
	'Ranong',
	'Rayong',
	'Ratchaburi',
	'Lopburi',
	'Loei',
	'Lampang',
	'Lamphun',
	'Sisaket',
	'Sakonnakhon',
	'Songkhla',
	'Satun',
	'Samutprakan',
	'Samutsongkhram',
	'Samutsakhon',
	'Sakaeo',
	'Saraburi',
	'Singburi',
	'Sukhothai',
	'Suphanburi',
	'Suratthani',
	'Surin',
	'Nongkhai',
	'Nongbualamphu',
	'Angthong',
	'Amnatcharoen',
	'Udonthani',
	'Uttaradit',
	'Uthaithani',
	'Ubonratchathani',
]