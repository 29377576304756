// export const BASE_URL = process.env.VUE_APP_BASE_URL
// export const API_URL = `http://localhost:16888/api/graphql`
// export const API_URL = `${process.env.VUE_APP_API_URL}/graphql`
// export const DOWNLOAD_SLIP_URL = `http://localhost:16888/api/download-slip`
// export const DOWNLOAD_SLIP_URL = `${process.env.VUE_APP_API_URL}/download-slip`

export const DOMAINS = {
	MAIN: 'main',
	NKPT: 'nkpt',
}
export const DOMAIN_DISPLAY_NAMES = {
	[DOMAINS.MAIN]: 'สาขาหลัก (กรุงเทพฯ)',
	[DOMAINS.NKPT]: 'สาขานครปฐม',
}

export const DOMAIN_TRANSLATION = {
	[DOMAIN_DISPLAY_NAMES.main]: DOMAINS.MAIN,
	[DOMAIN_DISPLAY_NAMES.nkpt]: DOMAINS.NKPT,
}

// local
// export const BASE_URL = 'http://localhost:11168';
// dev
export const BASE_URL = 'https://dev.cuvdl.com';
// prod
// const BASE_URL = 'https://cuvdl.com';

const API_URLS = {
  [DOMAINS.MAIN]: `${BASE_URL}/main/api`,
	[DOMAINS.NKPT]: `${BASE_URL}/nkpt/api`,
};

const ENDPOINTS = {}
for (const [domain, url] of Object.entries(API_URLS)) {
  ENDPOINTS[domain] = {
    GQL: `${url}/graphql`,
    DOWNLOAD_SLIP: `${url}/download-slip`,
  }
}

const DOMAIN_STORAGE_KEY = 'cuvdl-domain'

export const GetCurrentDomain = () => localStorage.getItem(DOMAIN_STORAGE_KEY)
export const SetCurrentDomain = (new_domain) => {
  CurrentDomain = new_domain
  localStorage.setItem(DOMAIN_STORAGE_KEY, new_domain)
}
export const GetCurrentDomainDisplayName = () => DOMAIN_DISPLAY_NAMES[GetCurrentDomain()].split(' ')[0]

if (!GetCurrentDomain()) {
  SetCurrentDomain(DOMAINS.MAIN)
}

let CurrentDomain = GetCurrentDomain()

export const GetGraphQLEndpoint = () => ENDPOINTS[CurrentDomain].GQL;
export const GetDownloadSlipEndpoint = () => ENDPOINTS[CurrentDomain].DOWNLOAD_SLIP;
