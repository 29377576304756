export const SubmissionStatus = Object.freeze({
	AWAIT_SAMPLE: 'รอรับตัวอย่าง',
	IN_PROGRESS: 'กำลังดำเนินการ',
	COMPLETED: 'เสร็จสิ้น',
	CANCELLED: 'ยกเลิก',
})

export const InvoiceStatus = Object.freeze({
	AWAIT_PAYMENT: 'รอชำระเงิน',
	PAID: 'ชำระเงินแล้ว',
})

export const ContactType = Object.freeze({
	ORG: 'องค์กร',
	PERSON: 'บุคคล',
})

export const UserType = Object.freeze({
	SUBMITTER: 201,
	ADMIN: 101
})

export const SubmissionType = Object.freeze({
	GENERAL: 'การตรวจทั่วไป',
	DISINFECTANT: 'ทดสอบประสิทธิภาพยาฆ่าเชื้อ',
})

export const DisinfectantTestType = Object.freeze({
	VIRUS: 'ไวรัส',
	BACTERIA: 'แบคทีเรีย',
	BACTERIA_CP: 'แบคทีเรีย (CP Protocol)',
})

export const GeneralTestDepartment = Object.freeze({
	VIROLOGY: 'ไวรัสวิทยา',
	SEROLOGY: 'ซีรั่มวิทยา',
	MOLECULAR: 'อณูชีววิทยา',
	BACTERIOLOGY: 'แบคทีเรียวิทยา',
})