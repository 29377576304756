import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'

import { apolloProvider } from '@/vue-apollo'
import { JWT_REFRESH } from '@/graphql/login'
import router from '@/router'

const CUVDL_TOKEN_KEY = 'cuvdl-token'

const NOT_LOGGED_IN_AUTH_DATA = {
	logged_in: false,
	is_admin: false,
	user_index: null,
	name: null,
	email: null,
}

let JWT_REFRESH_TIMER;

export function get_jwt () {
	// if (typeof localStorage !== 'undefined') {
	// 	return localStorage.getItem(AUTH_TOKEN)
	// }
	// return ""
	return Cookies.get(CUVDL_TOKEN_KEY)
}

function set_jwt (jwt) {
	// if (typeof localStorage !== 'undefined' && jwt) {
	// 	localStorage.setItem(AUTH_TOKEN, jwt)
	// }
	Cookies.set(CUVDL_TOKEN_KEY, jwt, { sameSite: 'strict' })
	write_auth_data_to_cache(
		apolloProvider.defaultClient.cache,
		get_auth_data_from_jwt(jwt)
	)
}

function remove_jwt () {
	// if (typeof localStorage !== 'undefined') {
	// 	localStorage.removeItem(AUTH_TOKEN)
	// }
	Cookies.remove(CUVDL_TOKEN_KEY)
	write_auth_data_to_cache(apolloProvider.defaultClient.cache, NOT_LOGGED_IN_AUTH_DATA)
}

async function refresh_jwt (old_jwt) {
	try {
		const res = await apolloProvider.defaultClient.mutate({
			mutation: JWT_REFRESH,
			variables: { jwt: old_jwt }
		})
		const jwt = res?.data?.refresh_token?.jwt
		// Replace old jwt with new jwt
		if (jwt) {
			set_jwt(jwt)
		}
	} catch (err) {
		console.log(err)
	}
}

async function jwt_refresh_if_expires_soon () {
	const jwt = get_jwt()

	if (!jwt) {
		return
	}

	const { exp } = jwt_decode(jwt)
	const jwt_exp = (new Date((exp*1000))).getTime()
	const now = Date.now()
	const until_exp = jwt_exp - now
	
	// jwt has expired
	if (until_exp <= 0) {
		remove_jwt()
		router.push({ name: 'login' })
		
	// jwt expires in < 2 hours
	// 2 hours = 7,200,000 ms
	} else if (until_exp < 7200000) {
		refresh_jwt(jwt)
	}
}

// Manually call this when user log in
export async function on_login (apolloClient, jwt) {
	try {
		await apolloClient.resetStore()
	} catch (e) {
		console.log('%cError on cache reset (login)', 'color: orange;', e.message)
	}
	
	set_jwt(jwt)
	init_jwt_refresh_timer()
}
	
	// Manually call this when user log out
export async function on_logout (apolloClient) {
	try {
		await apolloClient.resetStore()
	} catch (e) {
		console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
	}
	
	remove_jwt()
	
	// Cancel checking of jwt expiry at intervals
	if (JWT_REFRESH_TIMER) {
		clearInterval(JWT_REFRESH_TIMER)
	}
}

function get_auth_data_from_jwt (jwt) {
	const {
		user_index,
		permissions,
		name,
	} = jwt_decode(jwt)
	
	return {
		logged_in: true,
		is_admin: permissions?.['SysAdmin'],
		user_index,
		name
	}
}

function write_auth_data_to_cache (cache, data) {
	cache.writeData({
		data: {
			auth: {
				__typename: 'Auth',
				...data
			}
		}
	})
}

// Set system to check the expiry of jwt at 1 hour intervals
function init_jwt_refresh_timer () {
	JWT_REFRESH_TIMER = setInterval(
		function () { jwt_refresh_if_expires_soon() },
		3600000 // 3,600,000 ms = 1 hour
	)
}

export function init_auth () {
	const jwt = get_jwt()
	if (jwt) {
		set_jwt(jwt)
		jwt_refresh_if_expires_soon()
		init_jwt_refresh_timer()
	} else {
		remove_jwt()
	}
}
