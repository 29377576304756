import gql from 'graphql-tag'

export const LOGIN = gql`
	mutation login (
		$email: String!,
		$password: String!
	) {
		login (
			email: $email,
			password: $password
		) {
			jwt
		}
	}
`

export const JWT_REFRESH = gql`
	mutation refresh_token {
		refresh_token {
			jwt
		}
	}
`

export const RESET_PASSWORD = gql`
	mutation reset_password (
		$token: String!,
		$new_pass: String!
	) {
		change_password_with_token (
			token: $token,
			new_pass: $new_pass
		)
	}
`

export const FORGOT_PASSWORD = gql`
	mutation forgot_password ($email: String!) {
		forgot_password (email: $email)
	}
`